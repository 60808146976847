body {
  font-family: "Inter";

  /* font-style: normal; */
  /* font-weight: 400; */
  /* font-size: 12px; */
  /* line-height: 16px; */
  /* color: #191F23; */
}

h2,
h3,
h4,
h5 {
  font-weight: 700;
}

/* h3 {
    font-family: 'Poppins', sans-serif;
} */

.cstm-boxes {
  background-repeat: no-repeat;
}

#wrapper {
  align-items: stretch;
  display: flex;
  width: 100%;
}

.left-sidebar {
  min-width: 264px;
  max-width: 264px;
  background: #5736ba;
}

.sticky-sidebar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
}

#main {
  display: flex;
  width: 100%;
  min-width: 0;
  min-height: 100vh;
  background: #f5f7fb;
  flex-direction: column;
  overflow: hidden;
}

.left-sidebar h4 {
  padding: 25px;
}
.left-sidebar ul {
  padding: 0px;
}
/* .left-sidebar ul li:first-child{
    background: #6646c5;
    display: block;

    font-size: 16px;
} */

.left-sidebar ul li {
  font-size: 16px;
  transition: 0.5s;
  border-bottom: 1px solid #6747c5;
}

.left-sidebar ul li a {
  color: #fff !important;
  display: block;
  transition: 0.5s;
  padding: 17px 12px;
  font-weight: 400 !important;
  font-size: 18px;
}
.left-sidebar ul li a:hover {
  transform: translateX(10px);
  transition: 0.5s;
}
.header-right-icon a {
  background: #5736ba;
  color: #fff !important;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  margin-left: 8px;
}
.header-right-icon a:hover {
  color: #fff;
}
.dashboard-header-top {
  background: #f3efff;
  padding: 10px 0;
}
.dashboard-top-header {
  box-shadow: 0 0 2rem 0 rgb(33 37 41 / 10%);
}
.content {
  padding: 2rem 3rem 1.5rem;
}
.collapsed-in {
  display: none;
}
.dashboard .ant-tabs-nav,
.dashboard .heading,
.dashboard .days-left {
  display: none !important;
}
.dashboard-copyright {
  background: #ececec;
  padding: 5px;
  height: auto !important;
}
.form-control.required {
  border-bottom: 1px solid red !important;
}
.label-color-red {
  color: red;
}

@media screen and (min-width: 768px) {
  /* .cstm-boxes {
        flex: 0 0 19.9% !important;
        max-width: 21.666667% !important;
    } */

  .col-md-2.left-sidebar-section {
    flex: 0 0 17.666667% !important;
    max-width: 17.666667% !important;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1230px !important;
  }
}
.tr {
  text-align: right;
}

#fill-the-field {
  border: 1px solid red !important;
  padding-left: 15px;
}
.show-pw {
  cursor: pointer;
  position: absolute;
  right: 47px;
  margin-top: -31px;
  color: #ed3836;
}
.newpass-show-pw {
  cursor: pointer;
  position: absolute;
  right: 67px;
  margin-top: -31px;
  color: #fff;
}

.new-reset .login-body .fa {
  color: #fff !important;
  font-size: 18px;
  padding: 4px 1px 5px 1px !important;
}
.d-flex p.timeandsec {
  font-size: 20px;
  color: #fff !important;
  font-weight: 800;
  border-radius: 12px;
  line-height: 20px;
  text-decoration: auto !important;
  width: 50px;
  height: 50px;
  background: #4f4f4f;
  margin: 0;
  padding-top: 15px;
}
.d-flex p.watchtimeend.timeandsec {
  font-size: 20px;
  color: #fff !important;
  font-weight: 800;
  border-radius: 12px;
  line-height: 20px;
  text-decoration: auto !important;
  width: 50px;
  height: 50px;
  background: red;
  margin: 0;
  padding-top: 15px;
}
.d-flex p.dotcolor {
  color: #4f4f4f !important;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-decoration: none !important;
  margin-left: 3px;
  margin-right: 4px;
}
.stopwatchText {
  margin-top: 9px;
  color: var(--Gray-2, #4f4f4f);
  font-family: Inter;
  width: 20%;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  padding-left: 14px;
  margin-right: 12px;
}
.watchtime .statictext {
  font-size: 12px;
  text-transform: capitalize;
  color: #4f4f4f !important;
  text-decoration: auto !important;
  margin-top: 6px;
  margin-right: 3px;
  font-weight: 400;
  cursor: pointer;
}
.watchtimeend .timeandsec {
  font-size: 25px;
  font-weight: 800;
  line-height: 20px;
  color: #000 !important;
}
.editorbody {
  color: rgba(0, 0, 0, 0.85) !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  line-height: inherit !important;
  font-weight: normal !important;
  font-size: 16px !important;
  font-family: "Roboto", sans-serif !important;
}
.uldatacollage {
  position: absolute;
  /* margin-top: 8%; */
  width: 81%;
  text-align: left;
  padding: 0px 0px 0px 0px;
  background: #fff;
  border: 1px solid #9e9e9e;
  height: 200px;
  border-radius: 5px;
  overflow: auto;
  z-index: 9999;
}
.uldatacollage li {
  padding: 5px 6px 5px 5px;
  border-bottom: 1px solid #cccccc;
  /* border-radius: 5px; */
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: capitalize;
}

.uldatacollage li:hover {
  padding: 5px 6px 5px 5px;
  background: #c5c5c55e;
  /* border-radius: 5px; */
  margin-bottom: 10px;
  cursor: pointer;
}
.autoheight {
  position: absolute;
  /* margin-top: 8%; */
  width: 81%;
  text-align: left;
  padding: 0px 0px 0px 0px;
  background: #fff;
  border: 1px solid #9e9e9e;
  height: auto;
  border-radius: 5px;
  overflow: auto;
}
.autoheight li {
  padding: 5px 6px 5px 5px;
  border-bottom: 1px solid #cccccc;
  /* border-radius: 5px; */
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.createcolor {
  color: #176ede;
  font-weight: 400;
  /* font-size: 20px; */
}
.ant-table-thead > tr > th {
  font-weight: 800 !important;
}
.teamaddform .input-titlle {
  text-transform: inherit;
}
.teamaddform .prizetittle.memberdetail:after {
  left: 15%;
}
.otherpronoun input {
  border-bottom: 1px solid #dbdbdb !important;
  margin-top: 15px;
  border-top: none;
  border-right: none;
  border-left: none;
}
.resetpass-main-div {
  background-color: #000;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1.3rem;
  color: #fff;
  margin-top: 8%;
}
.resetpass-main-div .reset-login-body {
  padding: 35px 22px;
  border-radius: 22px;
}
.resetpass-main-div .reset-login-body h1 {
  color: #fff;
}
.resetpass-main-div .reset-login-body input {
  color: #fff;
  padding: 10px 10px 10px 16px !important;
}
.resetpass-main-div .reset-login-body button {
  background-color: #fff;
  color: #272727;
  border: 1px solid #000000;
  font-weight: 600;
  border-radius: 6px;
}
.resetpass-main-div .reset-login-body .fa {
  color: #fff !important;
  font-size: 18px;
  padding: 5px 10px 10px 10px;
}
.reset-new-pass {
  color: #fff !important;
}
.new-reset input[type="text"].form-control,
.new-reset input[type="password"].form-control {
  color: #fff !important;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #ed3836;
  color: #fff;
  font-size: 1em;
}
.reset-login-body {
  padding: 41px 38px;
  /* text-align: center; */
  border-radius: 22px;
}
.reset-login-body p {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 20px;
}
.reset-login-body h1 {
  font-size: 30px;
  font-weight: 800;
  /* margin-bottom: 20px; */
}
.reset-login-body label {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.quizaddmodal .modal-dialog.modal-sm {
  max-width: 1400px !important;
}

.quizaddmodal.modal.show .modal-dialog {
  top: 0%;
}
