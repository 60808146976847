.quiz-wrapper {
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #d1d0d0;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  .quiz-player-container {
    max-width: 100%;
    padding: unset;
  }

  /* Header Styles */
  .quiz-player-container .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    position: sticky;
    top: 0;
    background-color: #f8fbfe;
    z-index: 10000;
  }

  .gp-logo {
    width: 5%;
  }

  .header-left {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: unset;
  }

  .opportunity-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .opportunity-logo {
    height: 30px;
  }

  .header-title {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }

  .header-right {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .time-left-text {
    color: #ff4757;
    border: 1px solid #ff4757;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .timer-block {
      background-color: #ffe8e8;
      padding: 2px 4px;
      border-radius: 4px;
    }
  }

  .finish-button {
    padding: 10px 16px;
    border: 1px solid #ff4757;
    color: #ff4757;
    background: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.5s ease;
  }

  .finish-button:hover {
    background-color: #ff4757;
    color: white;
  }

  .finish-button:disabled {
    background-color: #ccc;
    border-color: #ccc;
    color: #888;
    cursor: not-allowed;
  }

  /* Content Layout */
  .content-wrapper {
    display: grid;
    grid-template-columns: 1fr 320px;
    gap: 24px;
    align-items: start;
    padding: 10px;
  }

  /* Question Area */
  .question-area {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    max-height: 82dvh;
    min-height: 82dvh;
    position: relative;
  }

  .question-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .question-meta {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .question-number {
    color: #ff4757;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .marks {
    font-weight: 600;
    color: rgb(232, 130, 28);
    background-color: rgb(248, 217, 191);
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    height: max-content;
  }

  .question-content {
    max-height: 52dvh;
    min-height: 52dvh;
    overflow-y: auto;
    padding-right: 5px;
  }

  .question-text {
    margin-bottom: 24px;
    line-height: 1.6;
    color: #333;
  }

  .question-media {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    .question-media-item-image {
      position: relative;
      display: inline-block;
      overflow: hidden;

      .question-media-image {
        display: block;
        max-width: 200px;
        max-height: 200px;
        width: auto;
        height: auto;
        object-fit: contain;
        object-position: center;
        border: 1px solid #b50505;
        cursor: pointer;
      }

      .question-media-video {
        display: block;
        max-width: 300px;
        max-height: 300px;
        width: auto;
        height: auto;
        object-fit: contain;
        object-position: center;
        border: 1px solid #b50505;
      }

      .question-media-audio {
        display: block;
      }
    }
  }

  .answer-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .answer-option {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: unset !important;
  }

  .answer-option .answer-checkbox {
    margin-top: unset;
    position: unset;
    width: 1rem;
    height: 1rem;
  }

  .answer-option.selected {
    background-color: #ffe8e8;
    border-color: #ff4757;
  }

  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding-right: 5px;
    position: absolute;
    bottom: 1rem;
    width: calc(100% - 48px);
  }

  .prev-btn {
    padding: 8px 16px;
    background: #ff4757;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .prev-btn:disabled,
  .next-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .next-btn {
    padding: 8px 16px;
    background: #ff4757;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .clear-btn {
    padding: 8px 16px;
    color: #ff4757;
    background: transparent;
    border: 1px solid #ff4757;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
  }

  .clear-btn:hover:not(:disabled) {
    background-color: #ffe8e8;
  }

  .clear-btn:disabled {
    color: #ccc;
    cursor: not-allowed;
    border: 1px solid #ccc;
  }

  /* Question Status Sidebar */

  .time-display {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 12px;
    font-weight: 500;
    padding: 4px 8px 6px 8px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
  }

  .timer-blocks {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .separator {
    font-weight: bold;
  }

  .questions-grid {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    max-height: 82dvh;
    min-height: 82dvh;
    overflow-y: auto;
  }

  .grid-header {
    margin-bottom: 16px;
  }

  .grid-title {
    width: 100%;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ddd;
    border-radius: 8px 8px 0px 0px;
    font-size: 15px;
    font-weight: 600;
    color: #333;
    cursor: pointer;
  }

  .status-indicators {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 16px;
    margin-top: 12px;
    padding: 5px 20px 0 20px;
  }

  .indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #666;
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ddd;
  }

  .dot.answered {
    background: #ff4757;
  }

  .dot.current {
    background: #4caf50;
  }

  .dot.skipped {
    background: #ff9800;
  }

  .indicator-text {
    color: #333;
    font-size: 12px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;
    padding: 0 20px 20px 20px;
  }

  .grid-button {
    padding: 8px;
    border: none;
    background: #f0f0f0;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }

  .grid-button.answered {
    background: #ff4757;
    color: white;
  }

  .grid-button.current {
    background: #4caf50;
    color: white;
  }

  .grid-button.skipped {
    background: #ff9800;
    color: white;
  }

  /* Large Screens (max-width: 1200px) */
  @media screen and (max-width: 1200px) {
    .content-wrapper {
      grid-template-columns: 1fr 280px;
      gap: 16px;
    }
  }

  /* Tablets (max-width: 992px) */
  @media screen and (max-width: 992px) {
    .quiz-player-container .header {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }

    .gp-logo {
      align-self: center;
    }

    .header-right {
      width: 100%;
      justify-content: space-between;
    }

    .content-wrapper {
      grid-template-columns: 1fr;
    }

    .questions-grid {
      min-height: unset;
    }

    .question-status {
      order: -1;
    }

    .time-display {
      justify-content: center;
    }

    .grid {
      grid-template-columns: repeat(10, 1fr);
    }
  }

  /* Small Tablets (max-width: 768px) */
  @media screen and (max-width: 768px) {
    .quiz-wrapper {
      padding: 12px;
    }

    .header-left {
      width: 100%;
      justify-content: space-between;
    }

    .question-header {
      flex-direction: row;
      gap: 12px;
    }

    .grid {
      grid-template-columns: repeat(5, 1fr);
    }

    .answer-option {
      padding: 10px;
    }

    .navigation-buttons {
      gap: 12px;
    }
  }

  /* Mobile (max-width: 576px) */
  @media screen and (max-width: 576px) {
    .quiz-wrapper {
      padding: 8px;
    }

    .gp-logo {
      width: 20%;
    }

    .header-right {
      flex-direction: column;
      gap: 12px;
      align-items: stretch;
    }

    .time-left-text {
      text-align: center;
    }

    .finish-button {
      width: 100%;
    }

    .question-area {
      padding: 16px;
    }

    .question-text {
      font-size: 14px;
    }

    .answer-options {
      gap: 8px;
    }

    .answer-option {
      font-size: 14px;
    }

    .navigation-buttons {
      flex-direction: column;
      gap: 8px;
    }

    .prev-btn,
    .next-btn {
      width: 100%;
      padding: 12px;
    }

    .status-indicators {
      gap: 8px;
    }

    .grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  /* Very Small Devices (max-width: 360px) */
  @media screen and (max-width: 360px) {
    .grid {
      grid-template-columns: repeat(3, 1fr);
    }

    .time-display {
      flex-direction: column;
      gap: 8px;
      align-items: center;
    }
  }

  /* Ensure timer blocks remain readable on all screens */
  @media screen and (max-width: 400px) {
    .time-block {
      padding: 4px;
      min-width: 24px;
      font-size: 14px;
    }
  }

  /* Add touch-friendly styles for mobile devices */
  @media (hover: none) {
    .answer-option,
    .grid-button,
    .finish-button,
    .prev-btn,
    .next-btn {
      -webkit-tap-highlight-color: transparent;
      min-height: 44px; /* Minimum touch target size */
    }

    .answer-option input[type="radio"] {
      width: 20px;
      height: 20px;
    }
  }
}
