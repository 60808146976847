.quiz-tittle {
  overflow: hidden;
  color: #ff6002;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  width: 100%;
  text-transform: uppercase;
  line-height: 1.6em;
}

.quiz-tittle:before {
  display: block;
  float: right;
  margin-top: 0.8em; /* half the line-height */
  border-top: 2px solid silver;
  width: 86%;
  content: "";
  margin-right: 48px;
}
.rdw-dropdown-selectedtext {
  color: #ff6002;
}
.quizform {
  width: 100%;
  box-shadow: none;
  padding: 0;
}
.quizform .float-left {
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 30px;
}
.quizform .choicesec .float-left {
  margin-bottom: 5px;
}
.questioninput {
  border: none;
  margin-left: 20px;
  width: 92%;
}
.removequestion {
  font-size: 18px;
  position: absolute;
  right: 18px;
}
.quizform .form-control {
  border: 2px solid #e3e3e370 !important;
  padding-left: 15px;
  border-radius: 4px;
  padding-top: 12px;
  height: 55px;
}
.question-form-body {
  /* padding: 38px 40px 15px 40px !important; */
  border-radius: 10px;
  padding: 25px;
  background: #fff;
  /* box-shadow: 0px 2px 30px #00000026; */
}

.quizsettingsection .form {
  padding: 2%;
  margin: 0;
  background-color: #fff;
}

.choicecss {
  width: 100%;
  box-shadow: none !important;
}
.quizform .float-left .quiz-tittle:before {
  display: block;
  float: right;
  margin-top: 0.8em;
  border-top: 2px solid silver;
  width: 85%;
  content: "";
  margin-right: 52px;
}
.quizform .removeiconq {
  position: relative;
  float: right;
  right: 0px;
  top: 0px;
}
.quizform .form-control.radiocss {
  border: none !important;
  padding-left: 0;
}
.quizform .form-row .form-group-col.col-md-6 {
  padding-right: 25px;
  padding-left: 5px;
}

.quizform .form-control.radiocss .form-check {
  margin-top: 0px;
}
.quiz_tab {
  padding: 12px;
  margin-right: 14px;
  border-radius: 12px;
  margin-bottom: 12px;
  background: #f6f6f6;
}
.quiz_tab.activebtn span {
  width: 40px;
  display: inline-block;
  height: 40px;
  background: #fff;
  color: #ff283a;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin: auto;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  margin-right: 11px;
}
.quiz_tab span {
  width: 40px;
  display: inline-block;
  height: 40px;
  background: #dcddde;
  margin: auto;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  margin-right: 11px;
  color: #18181b;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.tab-align-center {
  justify-content: center;
}
.quizaddmodal .close {
  position: absolute;
  right: 18px;
  width: 30px;
  height: 30px;
  border: 1px solid red;
  color: red;
  border-radius: 50%;
}
#questionimg {
  margin-top: 12px;
  color: red;
  width: 20px;
  right: 27px;
  position: absolute;
  top: 26px;
  cursor: pointer;
  font-size: 20px;
}
.hideinput {
  display: none;
}
#choicequestionimg {
  margin-top: 12px;
  color: red;
  width: 20px;
  right: 54px;
  position: absolute;
  top: 3px;
  font-size: 20px;
  z-index: 9999;
  cursor: pointer;
}
.radiocss#fill-the-field {
  border: 1px solid red !important;
  padding-left: 15px;
  height: 48px;
}
.quizform .form-control#quizfill-the-field {
  border: 1px solid red !important;
  padding-left: 15px;
  height: 55px;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  accent-color: red !important;
}
.choicepreviewimg {
  width: 45px;
  left: 46px;
  position: absolute;
  top: -2px;
}
.questionpreviewimg {
  width: 11%;
  float: left;
}
.removechoiceimg {
  position: absolute;
  left: 62px;
  color: #fff;
  width: 16px;
  border: 1px solid red;
  border-radius: 50%;
  height: 16px;
  top: 8px;
  padding-left: 5px;
  background: red;
  font-size: 9px;
}
.quizform
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  float: right;
  position: absolute;
  right: 0px;
  top: 28px;
}
.quizform
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  overflow: hidden;
  color: #ff6002;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  text-align: left;
  width: 100%;
  text-transform: uppercase;
  line-height: 1.6em;
}
.quizform .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border-bottom: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.ant-collapse-content {
  border-top: none;
}
.ant-collapse-header-text:before {
  display: block;
  float: right;
  margin-top: 0.8em;
  border-top: 2px solid silver;
  width: 85%;
  content: "";
  margin-right: 48px;
}
.quizform .ant-collapse,
.quizform .ant-collapse > .ant-collapse-item {
  border: none;
  background-color: #eff4f900 !important;
}
.chooseoption {
  width: 200px;
  color: red !important;
  padding-left: 0;
  cursor: pointer;
  margin-left: -11px;
  margin-bottom: 40px;
}
.quizform .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
}
.quizform .input-titlle {
  font-weight: 600;
}
.quizform .anticon svg {
  font-size: 16px;
  margin-top: -8px;
  margin-left: 6px;
}
.quizform .ant-select-arrow .anticon svg {
  font-size: 16px;
  margin-top: 0px;
  margin-left: 6px;
  fill: #000 !important;
}
.addquizbtn {
  margin-right: 0px !important;
}
.quizsettingsection .hosting_bottom {
  padding: 0 2%;
  position: relative;
  background-color: #fff !important;
}
.quizsettingsection .input-titlle {
  text-transform: initial;
}
.choicesec input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  z-index: 999;
  accent-color: red !important;
  font-size: 31px;
  position: absolute;
  margin-top: 8px;
  padding-right: 24px;
  cursor: pointer;
  left: 17px;
}
.quizdetailmsg ul {
  padding-left: 18px;
}
.uploader.questionupload #start {
  padding: 6%;
}
.nonecolor {
  color: #000 !important;
}
/* .quizform .cke_chrome
{
  border: none;
}
.quizform .cke_reset
{
  border: 0.5px solid;
} */
.quizsettingsection svg {
  font-size: 15px;
}
.cke_reset.nodatafound {
  border: 1px solid red !important;
}
.ant-collapse-content-box .ant-select-arrow .anticon svgg {
  fill: #000 !important;
}
.editchoiceimg {
  width: 34px;
  margin-left: 22px;
}
