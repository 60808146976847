.upload-container {
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  max-height: 60vh;
  overflow-y: auto;
}

h2 {
  color: #333;
  font-size: 20px;
}

.upload-box {
  border: 2px dashed #ff0000;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  margin: 20px auto;
  cursor: pointer;
  background-color: #ffeeee;
  transition: background 0.3s ease-in-out;
}

.upload-box:hover {
  background-color: #ffd9d9;
}

.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.upload-icon {
  font-size: 40px;
  color: #ff0000;
}

.upload-box span {
  font-size: 16px;
  font-weight: bold;
  color: #ff0000;
}

.upload-box p {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.file-input {
  display: none;
}

.file-name {
  margin: 10px 0;
  color: #333;
  font-weight: bold;
}

h3 {
  font-size: 18px;
  color: #333;
  margin-top: 20px;
}

.instructions {
  text-align: left;
  margin: 10px 0;
  padding: 10px;
  background: #ffeeee;
  border-radius: 5px;
  font-size: 14px;
}

.instructions li {
  margin: 5px 15px;
  list-style: disc;
  font-size: 16px;
}

.download-btn {
  background: #ff0000;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  transition: background 0.3s;
  float: left;
}

.download-btn:hover {
  background: #b30000;
}

.download-icon {
  margin-right: 8px;
}
