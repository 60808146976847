/* Timer.css */
.timer-wrapper {
  margin-right: 5px;
}

.timer-container {
  width: max-content;
  position: relative;
  padding-bottom: 4px;
  background: linear-gradient(
    to left,
    transparent calc(100% - var(--progress)),
    #ffe8e8 calc(100% - var(--progress))
  );
}

.timer-text {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.timer-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(
    to right,
    #ff4757 var(--progress),
    #e0e0e0 var(--progress)
  );
  transition: all 1s linear;
}

.reset-button {
  margin-top: 16px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.reset-button:hover {
  background-color: #e0e0e0;
}
